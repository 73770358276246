import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ParallaxProvider } from 'react-scroll-parallax'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import ScrollToTop from './ScrollToTop'

ReactDOM.render(
  <Router>
    <ParallaxProvider>
      <ScrollToTop />
      <App />
    </ParallaxProvider>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
