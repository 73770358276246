export const art = {
  pixelThings: [
    {
      src: '/images/hot chocolate.gif',
      width: 1,
      height: 1
    },
    {
      src: '/images/sepiroth.gif',
      width: 1,
      height: 1
    },
    {
      src: '/images/roman.png',
      width: 3,
      height: 3
    },

    {
      src: '/images/courageous_thief_elf_anim.gif',
      width: 1,
      height: 1
    },
    {
      src: '/images/nes_with_feedback_outline.gif',
      width: 4,
      height: 3
    },
    {
      src: '/images/smith.gif',
      width: 4,
      height: 4
    },

    {
      src: '/images/Akiraposter.png',
      width: 3,
      height: 4
    },

    {
      src: '/images/doge_colour_gif.gif',
      width: 1,
      height: 1
    },

    {
      src: '/images/christmas_elf.gif',
      width: 5,
      height: 4
    },
    {
      src: '/images/sleeping_kitty.gif',
      width: 4,
      height: 3
    },

    {
      src: '/images/scarf.png',
      width: 4,
      height: 3
    }
  ],
  isometricThings: [
    {
      src: '/images/lighthouse.png',
      width: 4,
      height: 3
    },

       {
      src: '/images/temple.png',
      width: 4,
      height: 3
    }, 
  ],
  digitalArtThings: [
    {
      src: '/images/mfdoom.png',
      width: 1,
      height: 1.1
    },
 
    {
      src: '/images/alice_in_wonderland.jpeg',
      width: 1,
      height: 1.2
    },
    {
      src: '/images/angel.jpeg',
      width: 1,
      height: 1.2
    },
 
    {
      src: '/images/look.jpeg',
      width: 1,
      height: 1.3
    },
    {
      src: '/images/bat.jpeg',
      width: 1,
      height: 1.3
    },
    {
      src: '/images/mushrooms.jpeg',
      width: 1,
      height: 1.3
    },
    {
      src: '/images/watching_you.jpeg',
      width: 1,
      height: 1.7
    },
    {
      src: '/images/embrace_death.jpeg',
      width: 1,
      height: 1.7
    },
    {
      src: '/images/underwater_machinations.jpeg',
      width: 1,
      height: 1.7
    },
    {
      src: '/images/colourful_face.jpeg',
      width: 1,
      height: 1.2
    },
    {
      src: '/images/gothgirl.png',
      width: 1.1,
      height: 1.1
    },
    {
      src: '/images/hollow_knight.jpeg',
      width: 1.1,
      height: 1.3
    },
   {
      src: '/images/redmfdoomblack.png',
      width: 1,
      height: 1.1
    },


    {
      src: '/images/annabeancolourcrop.png',
      width: 1.5,
      height: 2
    },
   {
      src: '/images/angel_small.png',
      width: 1,
      height: 1.1
    },
    {
      src: '/images/rustedCarCropped.png',
      width: 3,
      height: 2
    },
 {
      src: '/images/afro.jpg',
      width: 1,
      height: 1.8
    },
    {
      src: '/images/eyes.jpg',
      width: 1,
      height: 2
    },
    {
      src: '/images/20210208081953.png',
      width: 1.5,
      height: 2
    },
    {
      src: '/images/20210208082031.png',
      width: 5,
      height: 4
    },
    {
      src: '/images/PXL_20210209_052129376.jpg',
      width: 1.5,
      height: 2
    },
    {
      src: '/images/PXL_20210110_025235792.jpg',
      width: 1.4,
      height: 2
    },

    {
      src: '/images/PXL_20210107_123357974.jpg',
      width: 1.4,
      height: 2
    },

    {
      src: '/images/PXL_20201208_160705833.jpg',
      width: 1.3,
      height: 2
    },
    {
      src: '/images/window.png',
      width: 3,
      height: 2
    },
    {
      src: '/images/breakfast.png',
      width: 3,
      height: 2
    },

    {
      src: '/images/hamsterboi.png',
      width: 1,
      height: 1
    },
    {
      src: '/images/kirby2.png',
      width: 1.5,
      height: 1
    }
  ]
}
